.about-container {
  position: relative;
  padding: 30px;
  background: rgba(25, 23, 36, .75);
  backdrop-filter: blur(80px);
  border-radius: 15px;
  width: 65vw;
  color: #9ccfd8;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: #eb6f92;
}

.header h2 {
  font-size: 1.6rem;
  font-weight: normal;
  margin: 5px 0 20px;
  color: #ebbcba;
}

p {
  font-size: 1.3rem;
  line-height: 1.2;
  margin-bottom: 15px;
}

.home-image {
  width: 10rem;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
}