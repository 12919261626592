.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  padding-top: 30px;
  justify-content: center;
  text-decoration: underline dotted;
  background: linear-gradient(to top, rgba(25, 23, 36, 0.9) 0%, rgba(25, 23, 36, 0.5) 40%, rgba(0, 0, 0, 0) 90%);
  border: none;
}

.footer-button {
  text-align: center;
  border-radius: 1em;
  padding: 7px;
}

.footer-button:hover{
  scale: 1.25;
  background-color: rgba(33, 32, 46, 0.6);
}

.glass-button {
  display: inline-block;
  padding: 8px;
  border-radius: 15px;
  backdrop-filter: blur(90px);
  cursor: pointer;
  background-color: rgba(33, 32, 46, 0.6);
  transition: all 0.3s ease;
  margin: 10px 10px 30px 10px;
}