:root {
  --color-bg1: #191724;
  --color-bg2: #232136;
  --color-interactive: 196, 167, 231;
  --blending: hard-light;
}

.gradient-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0; 
  svg {
    display: none;
  }

  .gradients-container {
    filter: url(#goo) blur(50px);
    width: 100%;
    height: 100%;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);
    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;
    opacity: 0.68;

  }
}