html, body {
  margin: 0;
  height: 100%;
  overflow: auto;
  font-family: "Libre Franklin", sans-serif;
  flex-direction: column;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 900px) {
  html, body {
    margin-bottom: 4rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: small;
  }
}
